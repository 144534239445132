import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        usuario: {},
        cliente: {},
        navigator: {},
        currentProduct: null,
        cart: [],
        grupos: [],
        produtos: [],
        configuracoes: {},
        ads: null,
        mesa: null,
        cupom: null,
        orderBump: [],
        bannerDisplayed: false,
        isInitialized: false,
        cardPaymentLoading: false,
    },

    getters: {
        getNavigator: state => {
            return state.navigator;
        },

        getCurrentProduct: state => {
            return state.currentProduct;
        },

        getCart: state => {
            return state.cart;
        },

        getBannerDisplayed: state => {
            return state.bannerDisplayed;
        },

        getAds: state => {
            return state.ads;
        },

        getMesa: state => {
            return state.mesa;
        },

        getCupom: state => {
            return state.cupom;
        },

        getUsuario: state => {
            return state.usuario;
        },
    },

    mutations: {
        setNavigator(state, payload) {
            state.navigator = payload;
        },

        setCurrentProduct(state, payload) {
            state.currentProduct = payload;
        },

        setCart(state, payload) {
            state.cart = payload;
        },

        addToCart(state, payload) {
            state.cart.push(payload);
        },

        setBannerDisplayed(state, payload) {
            state.bannerDisplayed = payload;
        },

        setAds(state, payload) {
            state.ads = payload;
        },

        setMesa(state, payload) {
            state.mesa = payload;
        },

        setCupom(state, payload) {
            state.cupom = payload;
        },

        setInitialized(state, payload) {
            state.isInitialized = !!payload;
        },

        setCardPaymentLoading(state, payload) {
            state.cardPaymentLoading = !!payload;
        },

        setUsuario(state, payload) {
            state.usuario = payload;
        },

        setOrderBump(state, payload) {
            state.orderBump = payload;
        },
    },

    actions: {
        setAppData({ state: st }, params) {
            st.grupos = params.grupos;
            st.produtos = params.produtos;
            st.configuracoes = params.configuracoes;

            st.isInitialized = true;
        },

        async userAuthenticate({ state: st }) {
            if (st.usuario?.id) {
                return;
            }

            try {
                const { data } = await Vue.prototype.$http.post('get-auth');
                st.usuario = data.data.user;
                st.cliente = data.data.cliente;
            } catch (e) {
                console.log(e)
            }
        },

        logout({ state: st }) {
            st.usuario = {};
            st.cliente = {};
        },
    },

    modules: {
        //
    },
});
