<template>
    <v-app class="darkness">
        <v-container fill-height>
            <v-row justify="center" align="center">
                <v-col cols="10" sm="8">
                    <v-img
                        max-width="100"
                        :src="getLogo"
                    />
                    <h1 class="white--text">A empresa que você está buscando não foi encontrada!</h1>
                    <br>
                    <h3 class="white--text">Verifique se o link de acesso está correto.</h3>
                    <div class="caption white--text">
                        {{ getUrl }}
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
export default {
    name: 'NotFound',

    data: () => ({
    }),

    computed: {
        getLogo() {
            return require(`@/assets/zapermenu-light.png`);
        },

        getUrl() {
            return window.location.href;
        },
    },

    methods: {
        //
    },
};
</script>

<style scoped>
.darkness {
    background-color: #333;
}
</style>