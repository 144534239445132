
<template>
    <BaseContainer menuHide="true">
        <template v-if="empNotFound" slot="principal">
            <NotFoundPage />
        </template>

        <template v-else slot="principal">
            <Menu
                :categorias="getCategoriasMenu"
                @typing="onTyping"
            />

            <v-main
                :style="{
                    'background-attachment': 'fixed',
                    'background-repeat': 'initial',
                }"
            >
                <v-container fluid>
                    <v-img
                        height="200"
                        class="mb-4"
                        :contain="true"
                        v-if="slides.length"
                        :src="slides[0].img"
                    />

                    <div class="flex-container mt-4" v-if="produtos.length">
                        <v-chip :color="isOpen.color" label text-color="white">
                            {{ isOpen.text }}
                        </v-chip>
                    </div>
                    <div class="flex-container mt-1" v-if="produtos.length && agendamentoObrigatorio">
                        <v-chip color="cyan" label text-color="white">
                            Agende seu pedido!
                        </v-chip>
                    </div>

                    <div class="flex-container" style="margin-top: 10px;">
                       <div v-if="hasDelivery" style="text-align: center; display: flex;">
                           <v-icon class="mr-2">mdi-clock</v-icon>
                           <b>Entrega<br>{{ formatPrazo(configuracoes.tempo_entrega) }}</b>
                        </div>
                       <div v-if="hasRetiradaBalcao" style="text-align: center; display: flex;">
                           <v-icon class="mr-2">mdi-clock</v-icon>
                           <b>Retirada<br>{{ formatPrazo(configuracoes.tempo_retirada) }}</b>
                        </div>
                    </div>

                    <div class="flex-container mt-4 mb-10">
                        <div style="text-align: center; display: flex;" @click="dialogHorarios = true">
                           <v-icon class="mr-1">mdi-information-outline</v-icon>
                           <b>Horários</b>
                        </div>
                        <div v-if="isGeolocation" style="text-align: center; display: flex;" @click="openMap">
                           <v-icon class="mr-1">mdi-map-marker</v-icon>
                           <b>Localização</b>
                        </div>
                    </div>

                    <v-alert
                        v-if="mural.ativo"
                        text
                        outlined
                        :color="mural.cor"
                        class="mt-4 mr-5"
                        style="white-space: pre-line;"
                    >
                        {{ mural.mensagem || '' }}
                    </v-alert>

                    <v-alert
                        v-if="fidelidade.pontuador_ativo"
                        outlined
                        prominent
                        class="mt-4 mr-5"
                        icon="mdi-gift-outline"
                    >
                        <b>Programa de fidelidade</b>
                        <br>A cada <b>R$ 1,00</b> em compras você ganha <b>1 ponto</b> que pode ser trocado por prêmios.
                    </v-alert>

                    <div v-if="loading">
                        <v-skeleton-loader
                            class="mx-auto"
                            max-width="160"
                            type="card"
                            height="200"
                            style="margin: 15px 0 15px 0;"
                            v-for="i in 4"
                            :key="i"
                        />
                    </div>

                    <div v-if="!loading && !produtos.length" class="no-products-message">
                        <v-icon large color="grey">mdi-alert-circle-outline</v-icon>
                        <p class="text-center" style="color: grey; font-size: 18px; margin-top: 10px;">
                            Opa! O cadastro de produtos está em andamento. Volte mais tarde para conferir nossas novidades!
                        </p>
                    </div>

                    <div
                        v-for="grupo in sortedGrupos"
                        :key="grupo.descricao"
                        class="pl-2"
                    >
                        <div v-if="getProdutosByGrupo(grupo.descricao).length">
                            <div
                                class="category mt-4"
                                :style="isDark ? 'color: #ccc;' : 'color: #555555;'"
                                :id="grupo.descricao.replace(/\s/g, '-')"
                                :ref="grupo.descricao"
                            >
                                {{ grupo.descricao }}
                            </div>

                            <div class="main-div">
                                <v-icon
                                    v-if="getProdutosByGrupo(grupo.descricao).length > 2"
                                    @click="chevron(grupo.id, 'left')"
                                    x-large
                                    class="chevron-left"
                                    :style="`color: ${isDark ? '#fff' : '#000'};`"
                                >mdi-chevron-left</v-icon>
                                <div :id="`grupo-${grupo.id}`" class="center-div">
                                    <v-card
                                        v-for="(item, index) in getProdutosByGrupo(grupo.descricao)"
                                        :key="index"
                                        @click="onClickProduct(item)"
                                        class="flex-container-product mr-3 mt-2 mb-4"
                                        min-width="185"
                                        max-width="185"
                                        elevation="8"
                                        tile
                                        :style="{
                                            'border': `solid 1px ${isDark ? '#505050' : '#c1c1c1'}`,
                                            'border-radius': '10px !important',
                                            'background-color': `rgb(${isDark ? '21 21 21' : ' 250 250 250'} / 60%) !important`,
                                            'opacity': estoqueDisponivel(item) ? '1' : '0.3',
                                        }"
                                    >
                                        <div class="flex-items-product">
                                            <v-img
                                                v-if="slides.length"
                                                :src="getUrl(item.imagem)"
                                                width="185"
                                                height="180"
                                                style="border-radius: 5px;"
                                            />
                                            <div v-if="configuracoes.exibir_estoque_cardapio && item.controle_estoque && item.estoque > 0" class="stock-chip">
                                                <span class="caption">{{ getEstoqueText(item) }}</span>
                                            </div>
                                        </div>
                                        <div class="flex-items-product px-2">
                                            <div>
                                                <div
                                                    class="product-title mt-2 mb-4"
                                                    :style="`color: ${isDark ? '#fff' : '#555'};`"
                                                >
                                                    {{ item.descricao }}
                                                </div>
                                                <div
                                                    :style="`color: ${isDark ? '#d2d2d2' : '#444'}`"
                                                >
                                                    <span class="product-details">
                                                        {{ item.detalhes }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="getValor(item)" class="flex-items-product px-2 my-3">
                                            <div
                                                v-if="item.pontos_ativo && fidelidade.resgate_ativo"
                                                class="my-2"
                                            >
                                                <v-chip
                                                    color="green lighten-5"
                                                    text-color="green"
                                                    style="font-size: 12px; font-weight: bold;"
                                                >
                                                    Resgate por {{ Math.round((+item.valor || item.valor_minimo) * item.pontos_multiplicador) }} pontos
                                                    <!-- Resgate a partir de {{ item.valor + (item.valor_minimo) * item.pontos_multiplicador }} pontos -->
                                                </v-chip>
                                            </div>

                                            <div v-if="+item.valor_antigo" style="font-size: 14px;" class="my-1">
                                                <v-chip color="green" text-color="white" class="mr-2" label x-small>
                                                    {{ getDiscount(item) }}%
                                                </v-chip>
                                                <span style="text-decoration: line-through; font-weight: bold; color: #757575;">
                                                    R$ {{ formatPrice(+item.valor_antigo) }}
                                                </span>
                                            </div>
                                            <div>
                                                <span style="color: #4CAF50; font-weight: bold; font-size: 17px;">
                                                    {{ getValor(item) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            v-if="item.pontos_ativo && fidelidade.resgate_ativo"
                                            style="position: absolute; top: -5px; right: -5px; display: flex;"
                                        >
                                            <v-btn v-if="item.valor" fab width="32" height="32" color="primary" readonly>
                                                <v-icon small color="white">mdi-gift-outline</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card>
                                </div>
                                <v-icon
                                    v-if="getProdutosByGrupo(grupo.descricao).length > 2"
                                    @click="chevron(grupo.id, 'right')"
                                    x-large
                                    class="chevron-right"
                                    :style="`color: ${isDark ? '#fff' : '#000'}; position: absolute; right: 10px;`"
                                >mdi-chevron-right</v-icon>
                            </div>
                        </div>
                    </div>
                    <v-divider/>
                </v-container>
                <v-btn
                    v-if="hasProduct"
                    fab
                    fixed
                    bottom
                    right
                    color="primary"
                    class="white--text"
                    x-large
                    @click="resumo"
                >
                    <v-icon>mdi-cart</v-icon>
                </v-btn>
            </v-main>

            <Footer/>

            <v-dialog v-model="dialogHorarios" max-width="450">
                <v-card>
                    <v-card-title>
                        <v-spacer/>
                        <span style="font-size: 18px;">Horários de funcionamento</span>
                        <v-spacer/>
                        <v-btn icon @click="dialogHorarios = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row
                            v-for="dia in configuracoes.horarios"
                            :key="dia.diaSemana"
                        >
                            <v-col :class="{ bold: currentIsoWeekday == dia.isoWeekday }">
                                {{ dia.diaSemana }}
                            </v-col>
                            <v-col :class="{ bold: currentIsoWeekday == dia.isoWeekday }">
                                {{ dia.aberto ? dia.horario : 'FECHADO' }}
                            </v-col>
                            <v-col v-if="dia.aberto && dia.segundoHorarioAtivo" :class="{ bold: currentIsoWeekday == dia.isoWeekday }">
                                {{ dia.segundoHorario }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Footer from '@/components/Footer';
import NotFoundPage from '@/pages/notFound/NotFoundPage';
import Menu from './Menu';
import { mapState, mapActions, mapMutations } from 'vuex';
import { clone } from '@/utils/utils';
import { isOpen, requireScheduling } from '@/utils/openingHours';

export default {
    name: 'Cardapio',

    components: {
        BaseContainer,
        Footer,
        NotFoundPage,
        Menu,
    },

    data: () => ({
        search: '',
        slides: [],
        socials: [],
        currentProduct: null,
        dialogHorarios: false,
        empNotFound: false,
        loading: false,
        headers: [
            {text: 'Item', value: 'item', sortable: false, align: 'start'}, // start center end
            {text: 'Valor', value: 'valor', sortable: false, align: 'center'},
            {text: 'Total', value: 'total', sortable: false, align: 'center'},
        ],
    }),

    computed: {
        ...mapState([
            'cart',
            'isInitialized',
            'configuracoes',
            'produtos',
            'grupos',
        ]),

        isDark() {
            return this.$vuetify.theme.isDark;
        },

        mural() {
            return this.configuracoes.mural || {};
        },

        hasProduct() {
            return this.cart.length
        },

        hasDelivery() {
            return this.configuracoes?.formas_entrega?.some(e => e.descricao === 'Delivery');
        },

        hasRetiradaBalcao() {
            return this.configuracoes?.formas_entrega?.some(e => e.descricao === 'Retirar no Balcão');
        },

        sortedGrupos() {
            const gruposMenu = clone(this.grupos);
            return gruposMenu.sort((a, b) => (+a.ordem > +b.ordem) ? 1 : ((+b.ordem > +a.ordem) ? -1 : 0));
        },

        isGeolocation() {
            return this.configuracoes.geolocalizacao?.latitude;
        },

        permitirAgendamento() {
            return this.configuracoes.agendamento?.ativo;
        },

        agendamentoObrigatorio() {
            return requireScheduling();
        },

        isOpen() {
            return isOpen();
        },

        getCategoriasMenu() {
            return this.grupos
                .filter(g => this.getProdutosByGrupo(g.descricao).length)
                .sort((a, b) => a.ordem - b.ordem);
        },

        currentIsoWeekday() {
            return this.moment().isoWeekday();
        },

        fidelidade() {
            return this.configuracoes.fidelidade || {};
        },
    },

    mounted() {
        this.initialize();
    },

    methods: {
        ...mapActions([
            'setAppData',
        ]),

        ...mapMutations({
            addToCart: 'addToCart',
        }),

        initialize() {
            if (this.isInitialized) {
                this.setConfiguracoes();
                return;
            }

            this.loading = true;

            this.$http.get('initialize').then(({ data }) => {
                if (data.empNotFound) {
                    this.empNotFound = true;
                    return;
                }

                this.setAppData(data.data);
                this.setConfiguracoes();
                this.setFacebookPixelId();
                this.setGoogleTagId();
            }).finally(() => (this.loading = false));
        },

        resumo() {
            this.$router.push(`${this.$route.params.emp}/resumo`);
        },

        onClickProduct(prod) {
            const produto = clone(prod);

            const isClosed = !this.isOpen.status;
            const notAllowScheduling = !this.permitirAgendamento;
            const isEmpBlocked = !this.configuracoes.ativo;

            if ((isClosed && notAllowScheduling) || isEmpBlocked) {
                this.notify('Estamos fechados agora, consulte nossos horários de atendimento', 'warning');
                return;
            }

            if (!this.estoqueDisponivel(produto)) {
                this.notify('Produto esgotado', 'warning');
                return;
            }

            let comboComPassos = false;
            produto.produtos?.forEach(c => (c.passos?.length && (comboComPassos = true)));
            produto.quantidade = 1;

            if (produto?.passos?.length || comboComPassos) {
                this.$store.commit('setCurrentProduct', produto);
                this.$router.push(`${this.$route.params.emp}/steps`);
                return;
            }

            this.addToCart(produto);
            this.$router.push(`${this.$route.params.emp}/resumo`);
        },

        estoqueDisponivel(produto) {
            if (!produto.controle_estoque || !this.configuracoes.controle_estoque) {
                return true;
            }
            return produto.estoque > 0;
        },

        getValor(produto) {
            return this.estoqueDisponivel(produto)
                ? (+produto.valor_minimo ? `A partir de R$ ${this.formatPrice(produto.valor_minimo)}` : (+produto.valor ? `R$ ${this.formatPrice(produto.valor)}` : ''))
                : 'PRODUTO ESGOTADO';
        },

        getProdutosByGrupo(grupo) {
            return this.produtos
                .filter(i => i.grupo.descricao == grupo)
                .filter(i => i.descricao.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()));
        },

        setConfiguracoes() {
            document.title = this.configuracoes.fantasia;

            this.slides.push({img: this.getUrl(this.configuracoes.logo)});

            this.$root.$emit('banner', this.configuracoes.banner);
        },

        setFacebookPixelId() {
            if (!this.configuracoes.facebook_pixel_id) {
                return;
            }

            if (document.querySelector('#facebook-pixel-id')) {
                return;
            }

            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = false;
            s.id = 'facebook-pixel-id';
            s.innerText = '!function(f,b,e,v,n,t,s)' +
                '{if(f.fbq)return;n=f.fbq=function(){n.callMethod?' +
                'n.callMethod.apply(n,arguments):n.queue.push(arguments)};' +
                'if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version="2.0";' +
                'n.queue=[];t=b.createElement(e);t.async=!0;' +
                't.src=v;s=b.getElementsByTagName(e)[0];' +
                's.parentNode.insertBefore(t,s)}(window, document,"script",' +
                '"https://connect.facebook.net/en_US/fbevents.js");' +
                `fbq("init", "${this.configuracoes.facebook_pixel_id}");` +
                'fbq("track", "PageView");';

            document.getElementsByTagName('head')[0].appendChild(s);
        },

        setGoogleTagId() {
            if (!this.configuracoes.google_tag_id) {
                return;
            }

            if (document.querySelector('#google-tag-id')) {
                return;
            }

            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.id = 'google-tag-script';
            s.src = `https://www.googletagmanager.com/gtag/js?id=${this.configuracoes.google_tag_id}`;
            document.getElementsByTagName('head')[0].appendChild(s);

            const s2 = document.createElement('script');
            s2.type = 'text/javascript';
            s2.async = false;
            s2.id = 'google-tag-id';
            s2.innerText = 'window.dataLayer = window.dataLayer || [];' +
                'function gtag(){dataLayer.push(arguments);}' +
                "gtag('js', new Date());" +
                `gtag('config', '${this.configuracoes.google_tag_id}');`;
            document.getElementsByTagName('head')[0].appendChild(s2);
        },

        getUrl(img) {
            if (!img?.url) {
                return require('@/assets/noimageavailable.png');
            }

            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },

        openMap() {
            const coordenadas = this.configuracoes.geolocalizacao,
                latitude = coordenadas?.latitude || '',
                longitude = coordenadas?.longitude || '';

            if (!longitude || !latitude) {
                return;
            }

            window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_blank').focus();
        },

        onTyping(value) {
            this.search = value;
        },

        chevron(id, direction) {
            const elem = document.querySelector(`#grupo-${id}`);
            let left = elem.scrollLeft;

            if (direction === 'left') {
                left -= 230;
            } else {
                left += 230;
            }
            elem.scroll({left, behavior: 'smooth'});
        },

        getEstoqueText(item) {
            return item.estoque > 1
                ? `Restam ${item.estoque} unidades`
                : 'Resta apenas 1 unidade';
        },

        getDiscount(item) {
            const preco = item.valor_minimo || item.valor;
            const descontoPercentual = ((item.valor_antigo - preco) / item.valor_antigo) * 100;
            return Math.round(descontoPercentual);
        },

        formatPrazo(prazo) {
            const tempo = prazo.split(' - ');
            return `${this.formatTimeString(tempo[0])} à ${this.formatTimeString(tempo[1])}`;
        },

        formatTimeString(min) {
            const hours = Math.floor(min / 60);
            const minutes = min % 60;

            if (hours > 0 && minutes > 0) {
                return `${hours}h${minutes}min`;
            } else if (hours > 0) {
                return `${hours}h`;
            } else {
                return `${minutes}min`;
            }
        },
    },
}
</script>

<style scoped>
html {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container  {
    padding-right: 1px;
    padding-left: 20px;
}

.v-card__text {
    padding: 0 12px 10px !important;
}

@media (min-width: 768px) {
    .container {
        width: 100%;
        padding-left: 38px;
    }

    .v-card__text {
        padding: 0 24px 20px !important;
    }
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.v-card__text, .v-card__title {
    word-break: normal; /* maybe !important  */
}

.category {
    font-size: 1.3em;
    font-weight: 700;
    font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
}

.main-div {
    /* width: 100vw; */
    /* height: 100vh; */
    display: grid;
    place-items: center;
    justify-content: flex-start;
    align-items: center;
}

.center-div {
    width: 100%;
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
}

.center-div::-webkit-scrollbar {
    overflow: hidden;
}

.product-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
    line-height: 1.2;
    font-weight: bold;
    font-size: 16px;
}

.product-details {
    -webkit-text-size-adjust: 100%;
    font-family: 'Helvetica Neue',Helvetica,Arial,sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-line;
}

.chevron-left {
    position: absolute;
    z-index: 1;
    left: -5px;
    cursor: pointer;
}

.chevron-right {
    position: absolute;
    z-index: 1;
    right: 0;
    cursor: pointer;
}

.stock-chip {
    position: absolute;
    top: 0;
    background: #8009e5;
    color: white;
    border-radius: 5px;
    padding: 0 5px;
}

.bold {
    font-weight: bold;
}

.flex-container-product {
    display: flex;
    flex-direction: column;
}

.flex-items-product:nth-child(1) {
    flex-grow: 0;
    order: 0;
}

.flex-items-product:nth-child(2) {
    flex-grow: 1;
    order: 0;
}

.flex-items-product:nth-child(3) {
    flex-grow: 0;
    order: 1;
}

.no-products-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 80px 0;
    color: #757575;
}
</style>
