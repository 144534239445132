
<template>
    <v-dialog :value="true" persistent max-width="600">
        <v-card>
            <v-card-title class="headline justify-center">
                <v-spacer/>
                Minha Conta
                <v-spacer/>
                <v-btn
                    @click="$emit('close')"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="text-center">
                <v-expansion-panels v-model="panel" multiple>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Meus Dados</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Nome"
                                        :value="cliente.nome"
                                        readonly
                                        filled
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Whatsapp"
                                        :value="cliente.whatsapp"
                                        readonly
                                        filled
                                        hide-details
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <h3>
                                        <v-icon class="mr-1" color="success">mdi-gift-outline</v-icon>
                                        {{ cliente.pontos || 0 }} pontos
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Pedidos <i class="ml-2">(últimos 6 meses)</i>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <MyOrders />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import MyOrders from './MyOrders';
import { mapState } from 'vuex';

export default {
    name: 'MyAccount',

    components: { MyOrders },

    data: () => ({
        panel: [0, 1],
        showPasswordConfirm: false,
    }),

    computed: {
        ...mapState([
            'cliente',
        ]),
    },
}
</script>

<style scoped>
</style>
