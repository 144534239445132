import Vue from 'vue';
import App from './App';
import store from './store';
import router from './router';
import moment from 'moment';
import axios from 'axios';
import VueTheMask from 'vue-the-mask';
import vuetify from './plugins/vuetify';
import 'moment/locale/pt-br';
import './registerServiceWorker';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.config.productionTip = false;

Vue.use(VueTheMask);
Vue.use(vuetify, {
    iconfont: 'mdi' // 'md' || 'mdi' || 'fa' || 'fa4'
});

Vue.mixin({
    methods: {
        notify(text, type = 'success') {
            setTimeout(() => this.$root.$emit('notify', {text, type}), 100);
        },

        formatPrice(value) {
            let val = (+value).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },

        formatDate(dh, format = 'DD/MM/YYYY HH:mm') {
            return dh ? this.moment(dh).format(format) : '';
        },
    },
});

const baseURL = (document.URL.indexOf('localhost') !== -1)
    ? 'http://localhost:8000/api'
    : 'https://webservice.zapermenu.com.br/api';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
Vue.prototype.$http = axios.create({
    baseURL: baseURL + '/cardapio/'
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
});
Vue.prototype.$urlAPI = baseURL;

Vue.prototype.moment = moment;

Vue.mixin({
    methods: {
        notify(text, type = 'success') {
            setTimeout(() => this.$root.$emit('notify', {text, type}), 100);
        },

        formatPrice(value) {
            let val = (+value).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    },
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
