
<template>
    <v-dialog :value="true" persistent max-width="600">
        <v-card>
            <v-card-title class="headline justify-center">
                <v-spacer/>
                {{ isTokenFilled ? 'Crie sua senha' : 'Verifique seu whatsapp' }}
                <v-spacer/>
                <v-btn
                    @click="$emit('close')"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text v-if="sendingCode" class="text-center mt-4 pb-8">
                <v-progress-circular
                    :size="50"
                    color="primary"
                    class="mb-4"
                    indeterminate
                />
                <h3>Enviado código de confirmação para o seu whatsapp...</h3>
            </v-card-text>

            <v-card-text v-else class="text-center">
                <v-alert
                    color="success"
                    outlined
                    class="mt-4 mx-4"
                    icon="mdi-check-circle"
                >
                    Um código de confirmação foi enviado para o seu Whatsapp {{ value }}
                </v-alert>

                <v-form ref="form">
                    <v-otp-input
                        length="6"
                        type="tel"
                        v-model="token"
                        @finish="$nextTick(() => $refs.nome.focus())"
                        :rules="[rules.empty]"
                        class="mb-3"
                        dense
                    />
                    <!-- <v-text-field
                        v-if="isTokenFilled"
                        label="CPF"
                        ref="cpf"
                        v-model="cpf"
                        v-mask="['###.###.###-##']"
                        @keypress.enter="$nextTick(() => $refs.email.focus())"
                        :rules="[rules.cpf, rules.empty]"
                        outlined
                        dense
                    /> -->
                    <!-- <v-text-field
                        v-if="isTokenFilled"
                        label="E-Mail"
                        ref="email"
                        v-model="email"
                        @keypress.enter="$nextTick(() => $refs.password.focus())"
                        :rules="[rules.email, rules.empty]"
                        outlined
                        dense
                    /> -->
                    <v-text-field
                        v-if="isTokenFilled"
                        label="Nome completo"
                        ref="nome"
                        v-model="nome"
                        @keypress.enter="$nextTick(() => $refs.password.focus())"
                        :rules="[rules.empty]"
                        outlined
                        dense
                    />
                    <v-text-field
                        v-if="isTokenFilled"
                        label="Crie sua senha"
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        @keypress.enter="$nextTick(() => $refs.passwordConfirm.focus())"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.empty]"
                        ref="password"
                        outlined
                        dense
                    />
                    <v-text-field
                        v-if="isTokenFilled"
                        label="Confirme sua senha"
                        v-model="passwordConfirm"
                        :type="showPasswordConfirm ? 'text' : 'password'"
                        @click:append="showPasswordConfirm = !showPasswordConfirm"
                        @keypress.enter="$nextTick(() => validateAccount())"
                        :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.empty]"
                        ref="passwordConfirm"
                        outlined
                        dense
                    />

                    <div style="display: flex;">
                        <v-btn
                            @click="createAccount"
                            color="primary"
                            :disabled="resendBtnDisabled"
                            text
                            small
                        >Reenviar código</v-btn>
                    </div>
                </v-form>
            </v-card-text>
            <v-card-actions v-if="!sendingCode">
                <v-btn
                    @click="validateAccount"
                    :loading="loading"
                    color="primary"
                    class="white--text"
                    large
                    block
                >
                    Confirmar
                    <v-icon class="ml-1">mdi-arrow-right</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    name: 'CreateAccount',

    props: ['value'],

    data: () => ({
        resendBtnDisabled: true,
        sendingCode: false,
        loading: false,
        showPassword: false,
        showPasswordConfirm: false,
        nome: '',
        email: '',
        cpf: '',
        token: '',
        password: '',
        passwordConfirm: '',
        rules: {
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inválido'
            },

            empty: value => !!value || 'Preenchimento obrigatório',

            cpf: value => value?.length >= 14 || 'Cpf incompleto',
        },
    }),

    mounted() {
        this.createAccount();
    },

    computed: {
        isTokenFilled() {
            return this.token.length === 6;
        },
    },

    methods: {
        async createAccount() {
            if (!this.value) {
                return;
            }

            this.resendBtnDisabled = true;

            const data = { whatsapp: this.value };
            await this.$http.post('create-account', data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify(resp.data.msg);
            }).finally(() => {
                this.sendingCode = false;
                setTimeout(() => (this.resendBtnDisabled = false), 60000);
            });
        },

        validateAccount() {
            if (!this.token) {
                this.notify('Informe o código recebido no seu Whatsapp', 'warning');
                return;
            }

            if (this.password.length < 8) {
                this.notify('A senha deve conter no mínimo 8 digitos', 'warning');
                return;
            }

            if (this.password != this.passwordConfirm) {
                this.notify('A senha não confere', 'warning');
                return;
            }

            if (!this.$refs.form.validate()) {
                this.notify('Preencha todos os campos', 'warning');
                return;
            }

            this.loading = true;
            const data = {
                token: this.token,
                password: this.password,
                nome: this.nome,
                whatsapp: this.value,
                // cpf: this.cpf,
                // email: this.email,
            };
            this.$http.post('validate-account', data).then(resp => {
                const data = resp.data

                if (data.type === 'warning') {
                    this.notify(data.msg, 'warning');
                    return;
                }

                this.login(data.data);
            }).finally(() => {
                this.loading = false;
            });
        },

        login(accessToken) {
            this.$emit('create', accessToken);
        },
    },
}
</script>

<style scoped>
</style>
