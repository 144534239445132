<template>
    <v-footer dark padless>
        <v-card-text class="white--text text-center" style="background-color: #1e1e1e; padding-top: 24px !important;">
            Siga nossas redes sociais!<br><br>
            <v-btn
                v-for="social in socials"
                :key="social.type"
                class="mx-4 white--text"
                icon
                :href="social.link"
                target="_blank"
            >
                <v-icon size="24px">
                    {{ social.icon }}
                </v-icon>
            </v-btn>
        </v-card-text>

        <v-card flat tile class="white--text text-center"
            style="width: 100%; padding-top: 12px;">
            <v-card-text class="white--text pt-5">
                {{ configuracoes.slogan }}<br><br>
                <span v-if="configuracoes.bairro != 0">
                    {{ configuracoes.bairro }} / {{ configuracoes.rua }}, {{ configuracoes.numero }}<br>
                    {{ configuracoes.municipio }} - {{ configuracoes.uf }}
                </span>
            </v-card-text>

            <v-card-text class="white--text">
                {{ new Date().getFullYear() }} — <strong>{{ configuracoes.fantasia }}</strong>
            </v-card-text>

            <v-divider/>

            <v-card-text v-if="configuracoes.permitir_anuncios" @click="goToLandingPage" class="white--text my-8">
                <div>
                    Desenvolvido por <b>Scheeren Company</b> com ☕ & ❤️
                </div>
                <div class="my-4">
                    <v-btn small>Quero criar meu Cardápio Digital</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Footer',

    data: () => ({
        socials: [],
    }),

    mounted() {
        this.setSocials();
    },

    computed: {
        ...mapState([
            'configuracoes',
        ]),
    },

    watch: {
        configuracoes() {
            this.setSocials();
        },
    },

    methods: {
        setSocials() {
            this.socials = [];
            this.socials.push({type: 'facebook', icon: 'mdi-facebook', link: this.configuracoes.facebook});
            this.socials.push({type: 'instagram', icon: 'mdi-instagram', link: this.configuracoes.instagram});
            this.configuracoes.whatsapp && this.socials.push({
                type: 'whatsapp',
                icon: 'mdi-whatsapp',
                link: 'https://api.whatsapp.com/send?phone=55' + this.configuracoes.whatsapp.replace(/\D/g, '')
            });
        },

        goToLandingPage() {
            window.open('https://use.zapermenu.com.br', 'blank');
        },
    },
}
</script>
