<template>
    <div>
        <v-app-bar app :class="classMenu" :style="cssVars">
            <v-btn
                @click="primaryDrawer.model = !primaryDrawer.model"
                icon
                :color="$vuetify.theme.contrast.color"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>
            <v-img
                @click="scrollTo('top')"
                max-width="90"
                :src="getLogo"
            />
            <v-spacer />

            <Account v-if="showAccount && !search" />

            <v-btn
                v-if="!search"
                @click="openSearch"
                icon
                :color="$vuetify.theme.contrast.color"
            >
                <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <v-text-field
                v-if="search"
                ref="search"
                @keyup="$emit('typing', $event.target.value)"
                label="Pesquise por um produto"
                prepend-inner-icon="mdi-magnify"
                style="width: 150px;"
                hide-details
                outlined
                dense
                dark
            />

            <v-btn
                v-if="search"
                @click="closeSearch"
                icon
                :color="$vuetify.theme.contrast.color"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer
            v-model="primaryDrawer.model"
            :temporary="true"
            app
            overflow
            :style="classSideMenu"
        >
            <v-list dense>
                <v-list-item
                    link
                    @click.stop="primaryDrawer.model = false"
                    :style="`border-left: 3px solid ${$theme.primary};`"
                >
                    <v-list-item-content>
                        <v-list-item-title>Início</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider dark class="my-4"/>

                <v-list-item
                    style="margin-left: 5px;"
                    v-for="categoria in categorias"
                    :key="categoria.descricao"
                    @click="scrollTo(categoria.descricao)"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ categoria.descricao }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import Account from '@/pages/auth/Account';
import { mapState } from 'vuex';

export default {
    name: 'Menu',

    props: ['menuHide', 'categorias'],

    components: {
        Account,
    },

    data: () => ({
        primaryDrawer: { model: null },
        menu: { text: '', back: '' },
        search: false,
    }),

    computed: {
        ...mapState([
            'configuracoes',
        ]),

        classMenu() {
            return this.$vuetify.theme.isDark ? 'app-bar-dark' : 'app-bar-light';
        },

        classSearch() {
            return this.$vuetify.theme.isDark ? 'search-dark' : 'search-light';
        },

        classSideMenu() {
            return this.$vuetify.theme.isDark
                ? 'padding-top: 30px; background-color: black !important;'
                : 'padding-top: 30px;';
        },

        cssVars() {
            return {
                '--bg-color': this.$vuetify.theme.custom.appBarColor,
                'padding': '0 0 10px 0'
            };
        },

        getLogo() {
            const theme = this.$vuetify.theme.contrast.theme || 'dark';
            const logo = require(`@/assets/zapermenu-${theme}.png`);
            return logo;
        },

        showAccount() {
            return this.configuracoes.fidelidade?.pontuador_ativo || this.configuracoes.fidelidade?.resgate_ativo;
        },
    },

    created() {
        this.$root.$on('menu', menu => (this.menu = menu));
    },

    methods: {
        back() {
            this.$router.push(this.menu.back);
        },

        scrollTo(categoria) {
            if (categoria === 'top') {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            }
            const el = document.querySelector('#' + categoria.replace(/\s/g, '-'));
            const y = el.getBoundingClientRect().top + window.pageYOffset - 100;

            window.scrollTo({top: y, behavior: 'smooth'});

            this.primaryDrawer.model = false;
        },

        openSearch() {
            this.search = true;
            setTimeout(() => this.$refs.search.focus(), 100);
        },

        closeSearch() {
            this.search = false;
            this.$emit('typing', '');
        },
    },
};
</script>

<style scoped>
.logo {
    font-family: fantasy;
    color: red;
    font-size: 22px;
    /* font-weight: 900; */
}

.search-dark {
    border: solid 1px #ccc;
    width: 114px;
    padding-left: 8px;
    background-color: #1b1b1b;
    height: 28px;
    color: #fff;
}

.search-light {
    border: solid 2px #ccc;
    width: 114px;
    padding-left: 8px;
    background-color: #fff;
    height: 28px;
    color: #444;
}

.app-bar-dark {
    background-color: var(--bg-color) !important;
}

.app-bar-light {
    background-color: var(--bg-color) !important;
}

.lateral-menu {
    padding-top: 30px;
    background-color: black !important;
}

.v-list-item__title {
    letter-spacing: 1px;
    font-size: 16px !important;
}

.v-text-field > .v-input__control > .v-input__slot {
    border-style: unset !important;
}
</style>
