import Vue from 'vue'
import VueRouter from 'vue-router'

import Cardapio from '../pages/cardapio/Cardapio';

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history', // hash
    base: process.env.BASE_URL,
    scrollBehavior() {
        // always scroll to top
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/:emp',
            component: Cardapio
        },
        {
            path: '/:emp/steps',
            component: () => import(/* webpackChunkName: "steps" */ '../pages/cardapio/Steps')
        },
        {
            path: '/:emp/resumo',
            component: () => import(/* webpackChunkName: "resumo" */ '../pages/resumo/Resumo')
        },
        {
            path: '/:emp/envio',
            component: () => import(/* webpackChunkName: "envio" */ '../pages/envio/Envio')
        },
        {
            path: '/:emp/pagamento/:pedUuid',
            component: () => import(/* webpackChunkName: "pagamento" */ '../pages/pagamento/Pagamento')
        },
        {
            path: '/imprimir/:emp/:ped',
            component: () => import(/* webpackChunkName: "imprimir" */ '../pages/imprimir/Index')
        },
        {
            path: '/redirect-to-whatsapp/:emp/:ped',
            component: () => import(/* webpackChunkName: "redirect-to-whatsapp" */ '../pages/redirectToWhatsapp/Index')
        },
        {
            path: '/:emp/acompanhe-seu-pedido/:pedUuid',
            component: () => import(/* webpackChunkName: "acompanhe-seu-pedido" */ '../pages/pedidoStatus/Index')
        },
        {
            path: '/cartao-visita/:uuid',
            component: () => import(/* webpackChunkName: "imprimir" */ '../pages/cartaoVisita/Index')
        },
        // {
        //     path: '/:emp/mp-test/:key',
        //     component: () => import(/* webpackChunkName: "mp-test" */ '../pages/test/Index')
        // },
        // {
        //     path: '/indicacao/:cod',
        //     component: () => import(/* webpackChunkName: "mp-test" */ '../pages/indicacao/Index')
        // },
        {
            path: '/',
            component: () => import(/* webpackChunkName: "home" */ '../pages/home/Home')
        },
        {
            path: '*',
            component: () => import(/* webpackChunkName: "home" */ '../pages/home/Home')
        }
    ]
});

export default router;
